

import req from "../methods/req"
import method from '../methods/method'

// 获取实时轨迹用户列表
export const locusList = function (data){
    return method.get_list({
        url:'/manage/crm.business/locusList',
        ...data
    })
}

// 获取地图数据
export const locusMap = function (data){
    return req({
        url:'/manage/crm.business/locusMap',
        ...data
    })
}

export const locusMapHistory = function (data){
    return req({
        url:'/manage/crm.business/locusMapHistory',
        ...data
    })
}

// 获取用户数据
export const locusTopInfo = function (data){
    return req({
        url:'/manage/crm.business/locusTopInfo',
        ...data
    })
}

export const historyTrack = function (data){
    return method.get_list({
        url:'/manage/crm.business/historyTrack',
        ...data
    })
}

export const todayTrack = function (data){
    return req({
        url:'/manage/crm.business/todayTrack',
        ...data
    })
}

